$(
	function() {
		$( window ).scroll(
			function(){
				app.floatingHeader();
			}
		);

		$("._").before('<div class="top-fix"></div>');
		app.floatingHeader();
		app.assignNumberControl();
		app.setSelectedLink();
		app.addRegisterCheckBoxes();
		app.addOverClass();
		app.correctSocialIcons();
		app.assignProductOver();
		app.setDropDowns();
		app.attachSvgEvents();
        app.addCoverSpan();
        app.correctBodyHeight();
		app.saveProductListInfo();
		$(window).resize(
			app.correctBodyHeight
		)

		app.hamburger.init({look: 1});

		var topLinks = [];
		$('.menu.big .top-title > a').each(
			function() {
				if (topLinks.length > 3) {
					return false;
				}
				var $this = $(this);

				topLinks.push({
					href: $this.attr('href'),
					html: $this.html()
				})
			}
		);

		app.hamburger.addTopLinks(topLinks);
	}
);

var app = {
	_vars: {
		_guid: '0ae1421c-bf8d-4b7c-b48a-99b747d0b255',
		v: '1.0.9'
	},
	fixTop: 160,
	_VERSION: 2,
	correctBodyHeight : function() {
		$('._').css('min-height', $(window).height())
	},
	setDropDowns : function() {
		var $drop = $('.dropdown');
		if ($drop.length === 0) {
			return;
		}
        $drop.click(
			function() {
				if ($(this).hasClass('opened')) {
					$(this).removeClass('opened');
				} else {
					$(this).addClass('opened just-opened');
					if (window.getSelection) {
						if (window.getSelection().empty) {  // Chrome
							window.getSelection().empty();
						} else if (window.getSelection().removeAllRanges) {  // Firefox
							window.getSelection().removeAllRanges();
						}
					} else if (document.selection) {  // IE?
						document.selection.empty();
					}
				}
			}
		);
		$('.dropdown a').click(
			function() {
				$(this)
					.closest('div')
					.find('> a')
					.html($(this).html());
			}
		);
		$(window).click(
			function() {
				$('.dropdown').not('.just-opened').removeClass('opened');
				$('.just-opened').removeClass('just-opened');
			}
		);
	},
	setDropDownValue : function(inSelector, inVal) {
		$(inSelector).find('> a').html(inVal);
	},
	setDropDownList : function (inSelector, inArr) {
		var html = "";
		for (var i in inArr) {
			html += '<li><a href="' + inArr[i]['href'] + '">' + inArr[i]['label'] + '</a></li>';
		}
		app.setDropDownValue(inSelector, '');
		$(inSelector).find('> ul').html(html);

		$(inSelector).find('ul a').click(
			function() {
				$(this)
					.closest('div')
					.find('> a')
					.html($(this).html());
			}
		);
	},
	buildTabs : function() {
		var html = $('.menu.big .selected ul').html();
		if (!html || app.categorySliderDisplay == 'N') {
			$('div.cat-tabs').hide();
			$('#drop-cats').closest('li').hide();
		}
		if (app.categoryDisplayingType != '') {
			$('.view-type').closest('li').hide();
		}
		$('div.cat-tabs')
			.html('<ul>' + html + '</ul>');
		$('#drop-cats ul')
			.html(html);

		$('div.cat-tabs, #drop-cats')
			.find('li')
			.each(
			function() {
				var id = $(this).attr('class').match(/\-(\d+)/)[1];
				$(this).find('a')
					.attr('href', '#catId=' + id)
					.click(
					function () {
						app.setCurrentCat(id);
					}
				);
			}
		);

		for (var i in this.categoryInfo) {
			var img = new Image();
			img.src = this.categoryInfo[i]['img'];
		}
		app.setCurrentCat()
	},
	getHashParam : function(paramName) {
		var arr = String(document.location.hash).match(new RegExp('(?:&|#)' + paramName + '=([^&]+)'));
		if (arr && arr[1]) {
			return arr[1];
		}
		return '';
	},
	setCurrentCat : function (inID) {
		if (!inID) {
			var urlId = app.getHashParam('catId');
			if (Number(urlId)) {
				inID = urlId;
			} else {
				inID = this.curCatId;
			}
		}
		this.curCatId = inID;
		if (window.admin) {
			admin.categoryEditId = this.curCatId;
		}
		var $li = $('div.cat-tabs .li-cat-' + inID)
			.addClass('selected');

		app.setDropDownValue('#drop-cats', $li.find('> a').html());

		$('div.cat-tabs li')
			.not($li)
			.removeClass('selected');

		this.setCategoryInformation();
	},
	setCategoryInformation : function() {
		var $img = $('.cat-image img');
		if ($img.length === 0) {
			$('.cat-image').append('<img/><div class="add-text-span"></div>');
			$img = $('.cat-image img').on(
				'load',
				function() {
					$(this).fadeTo(
						200,
						1
					);
				}
			);
		}
		var that = this;

		$('#drop-sub-cats').stop();

		if (that.categoryInfo && that.categoryInfo[app.curCatId] && that.categoryInfo[app.curCatId]['children']) {
			var arr = that.categoryInfo[app.curCatId]['children'];
			$('#drop-sub-cats').fadeIn();
			var tmp = {};
			tmp[app.curCatId] = {
				label : '--',
				href : 'javascript:app.showCaruselForCategory(' + app.curCatId + ')'
			};
			for (var i in arr) {
				tmp[i] = {
					label : arr[i],
					href : 'javascript:app.showCaruselForCategory(' + i + ')'
				};
			}
			app.setDropDownList('#drop-sub-cats', tmp);
		} else {
			$('#drop-sub-cats').fadeOut();
		}
		$('.cat-image').attr('id', 'cat-' + app.curCatId);

		$img.stop()
			.fadeTo(
			200,
			0,
			function() {
				try {
					$img.attr('src' , that.categoryInfo[app.curCatId]['img']);
					if (that.categoryInfo && that.categoryInfo[app.curCatId] && that.categoryInfo[app.curCatId]['txt']) {
						$('div.add-text-span').html(that.categoryInfo[app.curCatId]['txt']);
					} else {
						$('div.add-text-span').html('');
					}
				} catch(e) {
					alert(app.curCatId);
				}

			}
		);
		that.showCaruselForCategory();
	},
	showCaruselForCategory : function(inID, withouChangeType) {

		var that = this;

		if (!withouChangeType) {
			this.setCatViewType(1);
		}

		if (!inID && !this.curCatId) {
            this.curCatId = 'all'
		}

		if (!this.categoryInfo) {
            this.categoryInfo = {};
		}

		if (inID) {
			this.curCatId = inID;
			this.categoryInfo[this.curCatId] = {};
		}
		if (!this.categoryInfo[this.curCatId]) {
            this.categoryInfo[this.curCatId] = {};
		}
		$('div.category-slider').removeClass('no-bg');
		$('.jcarousel-skin-category').fadeTo(
			300,
			0,
			function() {
				try {
                    var html = that.categoryInfo[that.curCatId]['html'];
				} catch(e) {
					return '';
				}

				var tmpId = that.curCatId;
				if (!html) {
					if (!that.categoryInfo[that.curCatId]['loading']) {
						that.categoryInfo[that.curCatId]['loading'] = 'start';
						$.get(
							that.productSourceUrl,
							{
								id : tmpId
							},
							function(data) {
								that.categoryInfo[tmpId]['loading'] = 'done';
								that.categoryInfo[tmpId]['html'] = data;
								if (tmpId == that.curCatId) {
									that.setProductsHTML();
								}
							}
						)
					}
				} else {
					that.setProductsHTML();
				}
			}
		)
	},
	setCatViewType : function (inType) {
		/*
		if (window.appMobile && window.appMobile.isMobileMode()) {
			inType = 2;
		}
		 */

		if (!this.lastSetType) {
			this.lastSetType = 1;
		}

		if (inType == this.lastSetType) {
			return;
		}

		var scrollTop = $(document).scrollTop();

		if (inType == 2) {
			$('.category-slider')
				.stop()
				.fadeOut();

			$('.category ._ .product-list')
				.stop()
				.fadeIn();

		} else {
			$('.category ._ .product-list')
				.stop()
				.fadeOut();

			$('.category-slider')
				.stop()
				.fadeIn();

			app.showCaruselForCategory(null, true);
		}

		$('._ .cat-controls ul li div.view-type a').removeClass('selected');
		$('._ .cat-controls .v-type-' + inType).addClass('selected');

		this.lastSetType = inType;
	},
	setProductsHTML : function() {
		var html = this.categoryInfo[this.curCatId]['html'];
		if (!html) {
			return;
		}
		$('#prod-list').html(html);

		app.saveProductListInfo();

		html = '';
		var cnt = 0;
		$('#prod-list .product-list2 div.item').each(
			function() {
				html += '<li class="item"><div class="item">' + $(this).html() + '</div></li>';
				cnt++;
			}
		);

		if (cnt < 4) {
			for (var i = 0; i < 2; i++) {
				html += html;
			}
		}

		$('.jcarousel-skin-category').remove();

		$('div.category-slider').html('<ul class="jcarousel-skin-category">' + html + '</ul>');

		$carusel = $('.jcarousel-skin-category').html(html);

		app.buildCarusel();

		$('div.category-slider').addClass('no-bg');

		$carusel
			.fadeTo(0, 0)
			.css('display', 'block')
			.fadeTo(300, 1);

		app.assignProductOver();
		app.initializeScrollProdName();
		if (app.categoryDisplayingType === 'L' || this.curCatId === 'all') {
			app.setCatViewType(2);
		}

		if (window.admin) {
			window.admin.assignEditor();
		}
	},
	buildCarusel : function() {
		try {
			jQuery('ul.jcarousel-skin-category')
				.jcarousel({
					wrap: 'circular',
					scroll: 3,
					auto: 3,
					itemFallbackDimension: 210,
					create: $('ul.jcarousel-skin-category').hover(function () {
							$(this).jcarousel({
								auto: 99999
							});
						},

						function () {
							$(this).jcarousel({
								auto: 3
							});
						})
				});
		} catch (e) {

		}
	},
	correctSocialIcons : function() {
		$('._ .social-icons > ul > li').each(
			function() {
				if ($(this).find('.sub').length == 0) {
					$(this).removeClass('with-sub');
				} else {
					$(this).addClass('with-sub');
				}
			}
		);
	},
	addOverClass : function() {
		$('._ div.div-search-field')
			.mouseover(
			function() {
				$(this).addClass('over');
			}
		)
			.mouseleave(
			function() {
				if ($(this).find("input.text").is(":focus")) {
					return;
				}
				$(this).removeClass('over');
			}
		);
		$('._ div.div-search-field input.text')
			.blur(
			function() {
				$(this).closest('div.div-search-field').removeClass('over');
			}
		)
			.focus(
			function() {
				$(this).closest('div.div-search-field').addClass('over');
			}
		)
	}
};